:root {
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 0.5em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */
    
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    
     /*General*/
    --container-max-width: 1200px;
    --innercontainer-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: 'Open Sans';
    --alternative-font-family: Roboto, Helvetica, Arial, sans-serif;
    
    /* change settings for headings */
    --header-bg-color: #fff;
    --header-padding: 0;
    
    /* change colors for topmenu */
    --topmenu-bg: var(--link-color);
    --topmenu-color: white;
    --topmenu-li-a-padding: 10px 15px;

    /* Typo */
    --scale-factor: 1.518;
    --base-font-size: 15px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    
    /*Colors & Backgrounds*/
    --text-color: #444;
    --heading-color: var(--link-color);
    --link-color: #222;
    --link-hover-color: #000;
    --alternative-color: #C2D629;
    --f-color: #fff;
    --map-marker-color: 000000;
}

@import "/css/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/css/basic_styling.css";
@import "/css/flex_grid.v1.1.css";
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/css/menu.v1.2.css";
@import "/css/magnific-popup.css";
@import "/fonts/Open Sans/all.css"; /* 300, 300, 300i, 300i, 400, 400, 400, 400i, 400i, 400i, 700, 700i */
@import "/fonts/Roboto/all.css"; /* 100, 100i, 300, 300i, 400, 400i, 500, 500i, 700, 700i, 900, 900i */
/*@import "/cssmodules/animate.css";*/


/* General bg-colors
---------------------------------------------------------*/

.maincolor {
    background-color: var(--link-color);
}

.white {
    background-color:white;
}

.lightgray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}

.f-color {
    color:var(--f-color);
}

.green {
    background-color:#C2D629;
}


/* Header
---------------------------------------------------------*/
header {
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    position:relative;
}

header .container {
    padding:0 0.5em;
}

header a {
    color:black;
}

header .topout {
    border-bottom:#eee dotted 1px;
}

header .topout a, header .topout {
    color:#CA1551;
    font-weight:300;
}

header svg.fa-icon {
    fill:#CA1551;
}

.topcontact > div {
    display: inline-block;
    padding: 0 .5em;
    transition: .5s ease;
}

.topdiv {
    background-image:url('/theme/intendit/graphics/topbild.jpg');
    background-position:bottom center;
    background-repeat:no-repeat;
    padding:0;
}

.topmenu {
    background:#f9f9f9;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
}

header .fixedtop {
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index:11;
    margin:0 auto;
    max-width: var(--container-max-width);
    width: 100%;
    background:white;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
}

.menu li {
    margin:0;
    font-family:var(--alternative-font-family);
    font-size:1rem;
}

.menu li a {
    background:#e5eff8;
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    padding:var(--topmenu-li-a-padding);
    margin:0 1px;
    color:#000;
    position: relative;
}

.menu li a:hover, .menu .active a {
    color:#E5EFF8;
    background:#83b7de;
}

.menu .last {
    padding-right:0;
}

.menu .dropdown ul {
    background:#F9F9F9;
    z-index:100;
}

.menu li:hover ul {
    margin-left:0;
}

.menu .dropdown ul {
    background:#e5eff8;
    border-bottom:0;
    padding:1.5em 0;   
}

.menu .dropdown li {
    padding:0 0.5em;
}

.menu .dropdown li a {
    background:transparent;
    border-top-left-radius:0px;
    border-top-right-radius:0px;
    padding:10px 15px;
    color:#000;
    border-right:none;
    border-bottom:#464646 dotted 1px
}

.menu .dropdown li a:hover, .menu .dropdown .active a {
    background:#e5eff8;
    color:#a6a6a6;
}

.menu .sub-menu-items li a {
    margin:0;
}

.logo {
}

.logo a {
    display:inline-block;
    text-align:center;
}



/* bildspel
---------------------------------------------------------*/
.start-slider {
    position:relative;
}

.slick {
    position: relative;
    margin-bottom: 0;
}

.slick-track {
    display: flex;
    flex-direction: row;
}   

.start-slider .slick,
.start-slider .slick-slide,
.start-slider .slick-substitute > div {
    height:400px;
}

.start-slider .slick-slide,
.start-slider .slick-substitute > div {
    background-size: contain;
    background-position: center center;
}

.slick-slider {
    margin:0 auto;
    position: relative;
}

.start-slider .slick-slide,
.start-slider .slick-substitute > div {
    background-size:contain;
    background-position: center right;
    background-repeat:no-repeat;
} 

.slick-title {
    color:white;
    font-size: calc(1 * var(--scale-factor-s)rem);
}

.slick-title h2 {
    display:block;
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    color:white;
    font-weight:700;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    margin-bottom:0.5em;

}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:2;
}

.slick-prev {
    left:50px;
    z-index:2;
}

.slick-next, .slick-prev {
    opacity:1;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.start-slider .slick-next:before, .start-slider .slick-prev:before {
    transition: 0.8s ease;
    -o-transition: 0.8s ease;
    -webkit-transition: 0.8s ease;
   background:rgba(0,0,0,0.7);
    padding:1em;
    font-family: Roboto;
    line-height:normal;
}

.start-slider .slick-next:hover:before, .start-slider .slick-prev:hover:before {
    background:white;
    color:black;
}

.slick-next:before {
    content:"»";
}

.slick-prev:before {
    content:"«";
}

.slick-dots {
    bottom:0px;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color: var(--alternative-color);
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background:var(--alternative-color);
    display:block;
}

.slick-dots li {
    border:var(--alternative-color) solid 1px;
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
}

/*.introcontent {
    position:absolute;
    top:0;
    right:0;
    left:0;
    height:100%;
}*/

.introcontent .inner-content {
    background-image: linear-gradient(141deg,#0080d4 0,#0195f7 55%,#0096f9 75%);
    background-image: linear-gradient(141deg,#CA1551 0,#CA1551 55%,#CA1551 75%);
    color:white;
    font-weight:300;
    padding:1em;
    text-align:center;
}

.introcontent .inner-content li {
    display:inline-block;
    padding:0 1em;
}

.introcontent .inner-content h2 {
    color:white;
}

.introcontent .inner-content .button {
    background:transparent;
    border:#fff solid 1px;
    padding:0.5em 1.5em;
}

.introcontent .inner-content .button:hover {
    background:var(--link-color);
    border:var(--link-color) solid 1px;
}

.introcontent .inner-content ul li {
    margin-left: 1em;
    position: relative;
}
.introcontent .inner-content ul li:before {
    content: "\2714";
    margin: 0 .3em 0 0;
    font-size: 1.2em;
    color: #fff;
    position: absolute;
    left: 0em;
}
.introcontent .inner-content ul {
    list-style-type: none;
    padding:0;

}

/* Content-startpage
---------------------------------------------------------*/
.block-2  {
    padding:2em 0;
}

.block-2 .inner-content {
    max-width:800px;
    margin:0 auto;
    text-align:center;
    border-bottom:#eee solid 1px;
}

/* startboxar */
.startbox {
    margin-top:3em;
}

.startbox-wrapper {
    text-align:center;
}

.startbox-wrapper .pop-img svg.fa-icon {
    height:auto;
    width: 4em;
    fill: var(--alternative-color);
    vertical-align:middle !important;
}

.startbox-wrapper h2 {
    margin-top:1em;
}

.startbox-wrapper a {
    color:var(--link-color);
}

.startbox a {
    display:block;
    text-decoration:none;
}

.startbox a:hover {
    color:#6E667B;
}

.startbox-wrapper {
    padding:2em;
    border:1px solid #e8e8e8;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.startbox-wrapper:hover {
    border:1px solid #c3c3c3;
}

.pop-img img {
    display:block;
}

.readmore {
    margin-top:0.5em;
    border-top:#c3c3c3 solid 1px;
}
.readmore > div {
    padding-top:0.5em;
    color:#222;
}

.startbox-wrapper .readmore svg.fa-icon {
    height:auto;
    width: 1em;
    fill: var(--link-color);
    vertical-align:middle !important;
}
/* zoomboxar */

.boxwrapper {
    min-height: 25vh;
    background-size: 0% 0%;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper h2 {
    color: #fff;
    margin: 0;
    text-align:center;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    z-index: 3;
    font-weight: 400;
}

.zoombox .boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.zoombox .boxwrapper[style]:before {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper figure img {
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .65s ease-in 0s;
    z-index: 2;
}

.zoombox .boxwrapper:hover:after {
    background: rgba(0,0,0,0.3);
    transition: background .65s ease-out 0s;
}

.zoombox .boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1.3);
    transition: transform .65s ease-out 0s;
}

.zoombox .box-teaser {
    z-index:101;
    opacity:0;
    text-align:center;
    transition: 0.5s ease;
    color:white;
    font-size:0.9rem;
    padding:0 2em;
    width:100%;
}

.zoombox .boxwrapper:hover .box-teaser {
    opacity:1;
}


/* block-3 */
.block-3 {
    padding:1.5em 0;
}

/* listing items
---------------------------------------------------------*/
.listing-item {
    margin-bottom:1.5em;
}

.listing-item p:first-of-type {
    padding-top:0;
    margin-top:0;
}

.listing-item h2 {
    margin-bottom:0;
}

.pagination {
}

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color:white;
}

.pagination ul li a {
    text-decoration: none;
}
    
/* Content
---------------------------------------------------------*/

.content h3 {
    margin-bottom:0;
}

.content p {
    margin-top:0;
}

table, tbody {
    width:100%;
    margin-top:0;
}

table td p {
    margin:0;
}

table td {
    border-color:#cecece;
    padding:0.4em;
}

tbody tr:nth-child(even) {
   background-color: #E5EFF8;
}
td, th {
    padding:0;
}

.top-img {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    height:30vh;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
} 

/* Contact
---------------------------------------------------------*/

body.kontakt {
    background:#f5f5f5;
}

.contact-block {
    box-shadow: 0px 0px 0px 1px #ececec;
}

.contact-block-l, .contact-block-2 {
    padding: var(--padding);
}

.contact-block-2 > div {
    border-bottom:#eee solid 1px;
    padding:1.5em 0;
}

.contact-block-2 .contactinfo p {
    margin:0;
}

.contact-block-2>div a {
    text-decoration:none;
}

figure {
    margin:0;
}
    

/* Footer
---------------------------------------------------------*/
footer {
    background-color:#C2D629;
}

.bokafooter {
    padding:2em 0;
    min-height:300px;
    background-color:#C2D629;
}

footer h4 {
    color:black;
    font-size:1.3rem;
    letter-spacing:0;
}

footer .innercontainer .contactinfo a {
    text-decoration:none;
    color:#000;
}

footer svg.fa-icon {
    fill:#000;
    height:calc(1 * var(--scale-factor)rem);
}

footer svg.fa-icon:hover {
    fill: var(--link-hover-color);
}

/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background:#100F0F;
    border-top:#151414 solid 1px;
    padding:0.5em 0;
    font-size:0.8rem;
}

.created-by .container, .intenditfooter {
    padding:0;
    margin:0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
    color:#fff;
}

.intenditfooter >* {
    margin: var(--margin);
}

.intenditfooter a, .intenditfooter {
    color:white;
}

/* sticky footer */
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin:0;
}


/* general
---------------------------------------------------------*/

.boltform textarea {
    min-height:120px;
}

input[type=color],
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=email],
input[type=file],
input[type=month],
input[type=number],
input[type=password],
input[type=phone],
input[type=range],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
select,
textarea {
    width: 100%;
    color:#222;
    padding:0.5em;
    border-radius:0;
}

button[type=submit], input[type=submit], .button, button {
    margin:0;
    background:var(--link-color);
    border:var(--link-color) solid 1px;
    color:white;
}

button[type=submit]:hover, input[type=submit]:hover, .button:hover, button:hover {
    background: var(--link-hover-color);
    border:var(--link-hover-color) solid 1px;
    color:white;
}

/* Floatlabels */
.boltforms-row {
    position: relative;
    margin-top: 1.6em;
}
.boltforms-row input[type="text"],.boltforms-row input[type="email"],.boltforms-row input[type="tel"],.boltforms-row  textarea,.boltforms-row select {
    z-index: 3;
    position: relative;
    background: transparent;
}
.boltforms-row label{
    z-index: 1;
    position: absolute;
    top: .55em;
    left: .5em;
    right: .5em;
    display: flex;
    align-items: center;
    width: auto;
    transition: transform 0.2s, opacity 0.2s;
    opacity: 0.8;
    font-size:0.9rem;
    margin-bottom:0;
}
.boltforms-row :focus ~ label, .boltforms-row .valid ~ label{
    opacity: 1;
    transform: translateY(-1.8em);
}
.boltforms-row label{
    height: 1.5em;
    display: flex;
    align-items: center;
}
option[value=""]{
    opacity: 0;
    font-size: 0;
    color: transparent;
}

.contactform .boltforms-row  {
    margin-bottom:0;
}

img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    background:#fff;
    max-width: var(--container-max-width);
    width: 100%;
}

.outerwrapper.container {
    padding:0;
    box-shadow:0 0 3px #ccc;
}

.innercontainer {
    max-width: var(--innercontainer-max-width);
    width: 100%;
    margin:0 auto;
    padding: 0.5em;
}


html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    background-color:#f2f2f2;
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
    letter-spacing:-1px;
    color: var(--heading-color);
    margin:0 0 0.3em 0;
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels{
    overflow:visible !important;
}

.fa-map-marker{
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 10px;
    background-position: center;
}



/* Media Queries
---------------------------------------------------------*/
 
@media (max-width: var(--breakpoint-menu)) {
    .menu.show, .menu {
        background:#E5EFF8 !important;
        box-shadow: 0 4px 2px -2px gray;
    }
    .introcontent {
        position:relative;
    }
    .introcontent .inner-content {
        max-width:100%;
    }
    .start-slider .slick-slide, .start-slider .slick-substitute>div {
        background-size:cover;
    }
    footer .innercontainer {
        text-align:center;
    }
    nav >.menu > li >a:before {
        content: "";
        transform:none;
        background:none;
    }
    .menu li a:hover:before, .menu .active a:before {
        transform: none !important;
        background:none;
    }
    .menu li a:hover, .menu .active a {
        color:#DB5F88;
    }
    .menu li, .menu li ul, .menu li:hover ul, .menu li a, .menu li li a {
        margin:0;
    }
    .start-slider .slick, .start-slider .slick-slide, .start-slider .slick-substitute>div {
        height:50vw;
    }
}

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
}

@media (max-width: var(--breakpoint-xs)) {
    h1 {
        font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
    footer {
        text-align:center;
    }
}


.slick:not(.slick-slider) > div:not(:first-child) {
    display: none;
}

.slick:not(.slick-slider) > div {
    height: 100%;
    min-height: 100vh;
}
